var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-card",
    { attrs: { title: _vm.title, loading: _vm.loading.detail } },
    [
      _c(
        "a-form-model",
        {
          ref: "formRef",
          attrs: {
            model: _vm.formState,
            rules: _vm.validationSchema,
            layout: "horizontal",
            "wrapper-col": { span: 12 },
            "label-col": { span: 8 },
            "label-align": "left"
          }
        },
        [
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c("a-col", { attrs: { flex: "1" } }, [
                _c(
                  "fieldset",
                  [
                    _c("legend", [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_general")) + " ")
                    ]),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_document_number"),
                          prop: "documentNumber"
                        }
                      },
                      [
                        _c("a-input", {
                          attrs: {
                            "allow-clear": "",
                            placeholder: _vm.$t("common.insert-text", {
                              text: _vm.$t("lbl_document_number")
                            }),
                            "data-testid": "amortization-doc-number",
                            disabled: _vm.disabledField
                          },
                          model: {
                            value: _vm.formState.documentNumber,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "documentNumber", $$v)
                            },
                            expression: "formState.documentNumber"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_transaction_type"),
                          prop: "transactionType"
                        }
                      },
                      [
                        _c("SelectAmortizationTransactionType", {
                          attrs: {
                            "has-create": "",
                            "data-testid": "amortization-transaction-type",
                            disabled: _vm.disabledField
                          },
                          model: {
                            value: _vm.formState.transactionType,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "transactionType", $$v)
                            },
                            expression: "formState.transactionType"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: { label: _vm.$t("lbl_branch"), prop: "branch" }
                      },
                      [
                        _c("SelectBranch", {
                          attrs: {
                            "label-in-value": "",
                            "data-testid": "amortization-branch",
                            disabled: _vm.disabledField
                          },
                          model: {
                            value: _vm.formState.branch,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "branch", $$v)
                            },
                            expression: "formState.branch"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_transaction_date"),
                          prop: "transactionDate"
                        }
                      },
                      [
                        _c("a-date-picker", {
                          staticClass: "w-100",
                          attrs: {
                            "allow-clear": "",
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_transaction_date")
                            }),
                            "data-testid": "amortization-transaction-date",
                            format: _vm.DEFAULT_DATE_FORMAT,
                            disabled: _vm.disabledField
                          },
                          model: {
                            value: _vm.formState.transactionDate,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "transactionDate", $$v)
                            },
                            expression: "formState.transactionDate"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_currency"),
                          prop: "currency"
                        }
                      },
                      [
                        _c("SelectCurrency", {
                          attrs: {
                            "label-in-value": "",
                            "data-testid": "amortization-currency",
                            disabled: _vm.disabledField
                          },
                          on: { change: _vm.onChangeCurrency },
                          model: {
                            value: _vm.formState.currency,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "currency", $$v)
                            },
                            expression: "formState.currency"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.isIdr
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_currency_rates"),
                              prop: "currencyRate"
                            }
                          },
                          [
                            _c("a-input-number", {
                              staticClass: "w-100",
                              attrs: {
                                parser: _vm.reverseFormatNumber,
                                formatter: _vm.formatterNumber,
                                precision: _vm.storeBaseDecimalPlace,
                                min: 1,
                                placeholder: _vm.$t("common.insert-text", {
                                  text: _vm.$t("lbl_currency_rates")
                                }),
                                "data-testid": "amortization-currency-rate",
                                disabled: _vm.disabledField
                              },
                              model: {
                                value: _vm.formState.currencyRate,
                                callback: function($$v) {
                                  _vm.$set(_vm.formState, "currencyRate", $$v)
                                },
                                expression: "formState.currencyRate"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_use_voucher_cash_out"),
                          prop: "useCashOut"
                        }
                      },
                      [
                        _c("a-switch", {
                          attrs: {
                            disabled: _vm.disabledField,
                            "data-testid": "amortization-use-cash-out"
                          },
                          model: {
                            value: _vm.formState.useCashOut,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "useCashOut", $$v)
                            },
                            expression: "formState.useCashOut"
                          }
                        })
                      ],
                      1
                    ),
                    _vm.formState.useCashOut
                      ? _c(
                          "a-form-model-item",
                          {
                            attrs: {
                              label: _vm.$t("lbl_bank_name"),
                              prop: "companyBank"
                            }
                          },
                          [
                            _c("SelectCompanyBank", {
                              attrs: {
                                disabled: _vm.disabledField,
                                "data-testid": "amortization-use-cash-out"
                              },
                              model: {
                                value: _vm.formState.companyBank,
                                callback: function($$v) {
                                  _vm.$set(_vm.formState, "companyBank", $$v)
                                },
                                expression: "formState.companyBank"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_description"),
                          prop: "description"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "extra",
                            fn: function() {
                              return [
                                _c("character-length", {
                                  attrs: { value: _vm.formState.description }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ])
                      },
                      [
                        _c("a-textarea", {
                          attrs: {
                            "allow-clear": "",
                            placeholder: _vm.$t("common.insert-text", {
                              text: _vm.$t("lbl_description")
                            }),
                            "data-testid": "amortization-description",
                            disabled: _vm.disabledField
                          },
                          model: {
                            value: _vm.formState.description,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "description", $$v)
                            },
                            expression: "formState.description"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: { label: _vm.$t("lbl_period"), prop: "period" }
                      },
                      [
                        _c("a-input-number", {
                          staticClass: "w-100",
                          attrs: {
                            min: 1,
                            placeholder: _vm.$t("common.insert-text", {
                              text: _vm.$t("lbl_period")
                            }),
                            disabled: _vm.disabledField,
                            "data-testid": "amortization-period"
                          },
                          model: {
                            value: _vm.formState.period,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "period", $$v)
                            },
                            expression: "formState.period"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "a-form-model-item",
                      {
                        attrs: {
                          label: _vm.$t("lbl_start_period"),
                          prop: "startPeriod"
                        }
                      },
                      [
                        _c("a-date-picker", {
                          staticClass: "w-100",
                          attrs: {
                            format: _vm.DEFAULT_DATE_FORMAT,
                            placeholder: _vm.$t("common.select-text", {
                              text: _vm.$t("lbl_start_period")
                            }),
                            disabled: _vm.disabledField,
                            "data-testid": "amortization-start-period"
                          },
                          model: {
                            value: _vm.formState.startPeriod,
                            callback: function($$v) {
                              _vm.$set(_vm.formState, "startPeriod", $$v)
                            },
                            expression: "formState.startPeriod"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.disabledField
                      ? _c(
                          "a-form-model-item",
                          { attrs: { "wrapper-col": { offset: 8 } } },
                          [
                            _c(
                              "a-button",
                              {
                                attrs: {
                                  "html-type": "button",
                                  type: "primary",
                                  "data-testid": "amortization-generate-period"
                                },
                                on: { click: _vm.handleGeneratePeriod }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t("lbl_generate")) + " "
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              ]),
              _c(
                "a-col",
                { attrs: { flex: "1" } },
                [
                  _c(
                    "a-row",
                    { attrs: { type: "flex", gutter: [16, 16] } },
                    [
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c(
                          "fieldset",
                          [
                            _c("legend", [
                              _vm._v(" " + _vm._s(_vm.$t("lbl_account")) + " ")
                            ]),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_prepaid_account"),
                                  prop: "prepaidAccount"
                                }
                              },
                              [
                                _c("a-select", {
                                  attrs: {
                                    options: _vm.prepaidAccountOptions,
                                    loading: _vm.loading.prepaidAccount,
                                    "label-in-value": "",
                                    "show-search": "",
                                    "filter-option": false,
                                    "dropdown-match-select-width": false,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.$t("lbl_prepaid_account")
                                    }),
                                    "data-testid":
                                      "amortization-prepaid-account",
                                    disabled: _vm.disabledField
                                  },
                                  on: { search: _vm.onSearchPrepaidAccount },
                                  model: {
                                    value: _vm.formState.prepaidAccount,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formState,
                                        "prepaidAccount",
                                        $$v
                                      )
                                    },
                                    expression: "formState.prepaidAccount"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_expense_account"),
                                  prop: "expenseAccount"
                                }
                              },
                              [
                                _c("a-select", {
                                  attrs: {
                                    options: _vm.expenseAccountOptions,
                                    loading: _vm.loading.expenseAccount,
                                    "label-in-value": "",
                                    "show-search": "",
                                    "filter-option": false,
                                    "dropdown-match-select-width": false,
                                    disabled: _vm.disabledField,
                                    placeholder: _vm.$t("common.select-text", {
                                      text: _vm.$t("lbl_expense_account")
                                    }),
                                    "data-testid":
                                      "amortization-expense-account"
                                  },
                                  on: { search: _vm.onSearchExpenseAccount },
                                  model: {
                                    value: _vm.formState.expenseAccount,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formState,
                                        "expenseAccount",
                                        $$v
                                      )
                                    },
                                    expression: "formState.expenseAccount"
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("a-col", { attrs: { span: 24 } }, [
                        _c(
                          "fieldset",
                          [
                            _c("legend", [
                              _vm._v(_vm._s(_vm.$t("lbl_leasing")))
                            ]),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_leasing_number"),
                                  prop: "leasingNumber"
                                }
                              },
                              [
                                _c("SelectLeasing", {
                                  attrs: {
                                    "label-in-value": "",
                                    accessor: "leasingNumber",
                                    status: ["Paid", "Submitted"],
                                    disabled: _vm.disabledLeasing,
                                    "data-testid": "amortization-leasing-number"
                                  },
                                  on: { "update:meta": _vm.onChangeLeasing },
                                  model: {
                                    value: _vm.formState.leasingNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formState,
                                        "leasingNumber",
                                        $$v
                                      )
                                    },
                                    expression: "formState.leasingNumber"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_leasing_contract_number"),
                                  prop: "leasingContractNumber"
                                }
                              },
                              [
                                _c("SelectLeasing", {
                                  attrs: {
                                    "label-in-value": "",
                                    accessor: "leasingContractNumber",
                                    status: ["Paid", "Submitted"],
                                    disabled: _vm.disabledLeasing,
                                    "data-testid":
                                      "amortization-leasing-contract-number"
                                  },
                                  on: { "update:meta": _vm.onChangeLeasing },
                                  model: {
                                    value: _vm.formState.leasingContractNumber,
                                    callback: function($$v) {
                                      _vm.$set(
                                        _vm.formState,
                                        "leasingContractNumber",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "formState.leasingContractNumber"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "a-form-model-item",
                              {
                                attrs: {
                                  label: _vm.$t("lbl_lessor_name"),
                                  prop: "lessor"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "ant-form-text",
                                    attrs: {
                                      "data-testid": "amortization-lessor"
                                    }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.formState.lessor
                                            ? _vm.formState.lessor.label
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c("a-col", { attrs: { flex: "1" } }, [
                _c(
                  "fieldset",
                  [
                    _c("legend", [
                      _vm._v(" " + _vm._s(_vm.$t("lbl_details")) + " ")
                    ]),
                    _c(
                      "a-row",
                      {
                        attrs: {
                          type: "flex",
                          gutter: [16, 24],
                          justify: "space-between"
                        }
                      },
                      [
                        _c(
                          "a-col",
                          [
                            _c(
                              "a-space",
                              [
                                _c(
                                  "a-button-group",
                                  [
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "primary",
                                          "html-type": "button",
                                          "data-testid":
                                            "amortization-add-unit",
                                          disabled: _vm.disabledField
                                        },
                                        on: { click: _vm.handleAddUnit }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("common.add-text", {
                                                text: _vm.$t("lbl_unit_code")
                                              })
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "a-button",
                                      {
                                        attrs: {
                                          type: "danger",
                                          "html-type": "button",
                                          disabled: _vm.disabledField,
                                          "data-testid":
                                            "amortization-delete-unit"
                                        },
                                        on: { click: _vm.handleDeleteUnit }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("common.delete-text", {
                                                text: _vm.$t("lbl_unit_code")
                                              })
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "a-space",
                                  [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(_vm.$t("lbl_number_of_copy"))
                                      )
                                    ]),
                                    _c("a-input-number", {
                                      attrs: { min: 1 },
                                      model: {
                                        value: _vm.numberOfCopies,
                                        callback: function($$v) {
                                          _vm.numberOfCopies = $$v
                                        },
                                        expression: "numberOfCopies"
                                      }
                                    }),
                                    _c("a-button", {
                                      attrs: {
                                        icon: _vm.isDoneCopy ? "check" : "copy"
                                      },
                                      on: { click: _vm.handleCopy }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "a-tooltip",
                                  { attrs: { title: _vm.$t("lbl_reset") } },
                                  [
                                    _c("a-button", {
                                      attrs: { icon: "rollback" },
                                      on: { click: _vm.handleResetUnit }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "a-col",
                          [
                            _c("a-pagination", {
                              attrs: {
                                size: "small",
                                "show-quick-jumper": "",
                                "default-page-size": 1,
                                total: _vm.formState.units.length,
                                "show-total": function(total) {
                                  return _vm.$t("common.total-text", {
                                    total: total,
                                    text: _vm.$t("lbl_unit_code")
                                  })
                                }
                              },
                              model: {
                                value: _vm.currentPageUnit,
                                callback: function($$v) {
                                  _vm.currentPageUnit = $$v
                                },
                                expression: "currentPageUnit"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _vm.formState.units.length > 0
                      ? [
                          _c(
                            "a-row",
                            { attrs: { type: "flex", gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [
                                  _c("a-table", {
                                    attrs: {
                                      "data-source": [
                                        _vm.formState.units[
                                          _vm.currentPageUnit - 1
                                        ]
                                      ],
                                      columns: _vm.unitColumn,
                                      size: "small",
                                      "row-class-name": function(_, index) {
                                        return index % 2
                                          ? "bg-white"
                                          : "bg-gray-light"
                                      },
                                      pagination: false
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "unitCode",
                                          fn: function(text, record) {
                                            return [
                                              _c("SelectUnitCode", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  "label-in-value": "",
                                                  "data-testid":
                                                    "amortization-unit." +
                                                    (_vm.currentPageUnit - 1) +
                                                    ".unit-code",
                                                  disabled:
                                                    _vm.disabledField &&
                                                    !!_vm.dtDetail.units[
                                                      _vm.currentPageUnit - 1
                                                    ].unitCode
                                                },
                                                on: {
                                                  "update:meta": function(
                                                    $event
                                                  ) {
                                                    return _vm.onChangeUnit(
                                                      record,
                                                      $event
                                                    )
                                                  }
                                                },
                                                model: {
                                                  value: record.unitCode,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      record,
                                                      "unitCode",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "record.unitCode"
                                                }
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "note",
                                          fn: function(text, record) {
                                            return [
                                              _c("a-textarea", {
                                                attrs: {
                                                  placeholder: _vm.$t(
                                                    "common.insert-text",
                                                    { text: _vm.$t("lbl_note") }
                                                  ),
                                                  "allow-clear": "",
                                                  "data-testid":
                                                    "amortization-unit." +
                                                    (_vm.currentPageUnit - 1) +
                                                    ".note",
                                                  disabled: _vm.disabledField
                                                },
                                                model: {
                                                  value: record.note,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      record,
                                                      "note",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "record.note"
                                                }
                                              }),
                                              _c("character-length", {
                                                attrs: {
                                                  value: record.note || ""
                                                }
                                              })
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      false,
                                      4003483658
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-row",
                            { attrs: { type: "flex", gutter: [16, 16] } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 12 } },
                                [
                                  _c("a-table", {
                                    attrs: {
                                      columns: _vm.periodColumn,
                                      "data-source":
                                        _vm.formState.units[
                                          _vm.currentPageUnit - 1
                                        ].periods,
                                      size: "small",
                                      pagination: {
                                        showTotal: function(total) {
                                          return _vm.$t("lbl_total_items", {
                                            total: total
                                          })
                                        }
                                      },
                                      "row-class-name": function(
                                        _record,
                                        index
                                      ) {
                                        return index % 2
                                          ? "bg-white"
                                          : "bg-gray-light"
                                      }
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "amount",
                                          fn: function(text, record) {
                                            return [
                                              _c("a-input-number", {
                                                staticClass: "w-100",
                                                attrs: {
                                                  parser:
                                                    _vm.reverseFormatNumber,
                                                  formatter:
                                                    _vm.formatterNumber,
                                                  precision:
                                                    _vm.storeBaseDecimalPlace,
                                                  min: 0,
                                                  placeholder: _vm.$t(
                                                    "common.insert-text",
                                                    {
                                                      text: _vm.$t("lbl_amount")
                                                    }
                                                  ),
                                                  "data-testid":
                                                    "amortization-unit-period-amount",
                                                  disabled: _vm.disabledField
                                                },
                                                on: {
                                                  change: _vm.onChangeAmount
                                                },
                                                model: {
                                                  value: record.amount,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      record,
                                                      "amount",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "record.amount"
                                                }
                                              })
                                            ]
                                          }
                                        },
                                        {
                                          key: "footer",
                                          fn: function() {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "text-right",
                                                  attrs: {
                                                    "data-testid":
                                                      "amortization-unit-period-subtotal"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        _vm.$t("lbl_subtotal")
                                                      ) +
                                                      " : " +
                                                      _vm._s(
                                                        _vm._f("currency")(
                                                          _vm.formState.units[
                                                            _vm.currentPageUnit -
                                                              1
                                                          ].subTotal
                                                        )
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      false,
                                      3922825836
                                    )
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      : [
                          _c(
                            "a-row",
                            { attrs: { type: "flex" } },
                            [
                              _c(
                                "a-col",
                                { attrs: { span: 24 } },
                                [_c("a-empty")],
                                1
                              )
                            ],
                            1
                          )
                        ]
                  ],
                  2
                )
              ])
            ],
            1
          ),
          _c(
            "a-row",
            { attrs: { type: "flex", gutter: [16, 16] } },
            [
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("common.total-text", {
                        text: _vm.$t("lbl_unit_code")
                      }),
                      value: _vm.formState.units.length
                    }
                  })
                ],
                1
              ),
              _c(
                "a-col",
                [
                  _c("a-statistic", {
                    attrs: {
                      title: _vm.$t("lbl_grand_total"),
                      value: _vm.formState.grandTotal
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "formatter",
                        fn: function(ref) {
                          var value = ref.value
                          return [
                            _c(
                              "span",
                              {
                                attrs: {
                                  "data-testid": "amortization-grand-total"
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm._f("currency")(value)) + " "
                                )
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "text-right mt-3" },
            [
              _c(
                "a-space",
                [
                  _c(
                    "a-button",
                    {
                      attrs: {
                        "html-type": "button",
                        "data-testid": "amortization-back"
                      },
                      on: { click: _vm.handleBack }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("lbl_back")) + " ")]
                  ),
                  _vm.$can("create", "amortization") && _vm.allowSaveDraft
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.draft,
                            "html-type": "button",
                            "data-testid": "amortization-draft"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleSubmit("draft")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("lbl_save_as_draft")) + " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.$can("update", "amortization") && _vm.allowUpdate
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.update,
                            "data-testid": "amortization-update",
                            type: "primary",
                            "html-type": "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleSubmit("update")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common.update-text", {
                                  text: _vm.$t("lbl_amortization")
                                })
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e(),
                  _vm.$can("submit", "amortization") && _vm.allowCreate
                    ? _c(
                        "a-button",
                        {
                          attrs: {
                            loading: _vm.loading.create,
                            "data-testid": "amortization-create",
                            type: "primary",
                            "html-type": "button"
                          },
                          on: {
                            click: function($event) {
                              return _vm.handleSubmit("create")
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("common.create-text", {
                                  text: _vm.$t("lbl_amortization")
                                })
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }